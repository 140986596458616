* {
  margin: 0;
  padding: 0;
  }
h1{
  font-family: 'BioRhyme', serif;
}
              /***********NAVBAR***************/
nav{
  background-color: black;
  border-bottom: #04C2C9 solid 3px;
  position: fixed;
  z-index: 99;
  width: 100%;
}
.navbar-container{
display: flex;
justify-content: space-between;
}

nav div ul{
  overflow: hidden;
  background-color: black;
}
nav div ul li, .name{
  list-style-type: none;
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.nav-item a:hover {
  color: #E31B6D;
  cursor: pointer;
}

.nav-item a.active {
  color: #E31B6D;
}

.fa-bars{
  background-color: none;
}

.icon {
  background: none;
  border-top: #04C2C9 solid 3px;
  color: #E31B6D;
  display: none;
  border: none;
  font-size: 30px;
}

      /*************HOME*******************/

.home{
background: black;
color: white;
height: 100vh;
}

.main-img{
width: 100%;
height: 100vh;
opacity: 0.06;
position: relative;
}

.desc{
font-family: 'Bebas Neue', cursive;
letter-spacing: 0.05em;
position: absolute;
top: 45%;
text-align: center;
width: 100%;
}

.desc h2{
  font-family: 'BioRhyme', serif;
  font-size: 3.2rem;
  font-weight: bold;
}
.desc-highlight{
color: #04C2C9;
}

.resume{
  margin-top: 20px;
  font-size: 1.2rem;
  color: #04C2C9;
  padding-top: 5px;
}
.resume:hover{
  text-decoration: none;
  color: #E31B6D;
}
      /********ABOUT**************/
      
.about{
  padding: 50px;
}

.about h1{
text-align: center;
margin-bottom: 10px;
}

.me{
  text-align: center;
}
.about img {
  border: 1px solid #ddd;
  border-radius: 5%;
  padding: 5px;
  width: 200px;
}

.skill{
  background-color: #DED;
  margin: 20px auto;
  position: relative;
}
.skill span{
position: absolute;
right: 0;
top: 0;
}

.skill-percentage{
background-color: #00A1A7;
}

.language{
  background-color: #04C2C9;
  color: white;
  z-index: 99;
  width: 100px;
  padding-left: 15px 
}

/*new*/
.about-me{
font-family: 'Barlow', sans-serif;
text-align: center;
max-width: 600px;
margin:auto;
}

.about-me h2{
font-family: 'Barlow', sans-serif;
}
.about-me p {
font-size: 1.15em;
}

.email-reach{
margin-top: 10px;
/* font-weight: bold; */
}
.email{
color: #E31B6D;
}

.social-media{
  margin-left: 25px;
}
.social-media a{
color: white;
padding-right: 20px;
}

.social-media a:hover{
color: #E31B6D;
}

.social-media a i{
font-size: 30px;
background-color: none;
padding: 10px;
}
/************PORTFOLIO*************/
.Portfolio{
  background-color: #F5F5F5;
  padding-top: 50px;
  padding-bottom: 50px;
}

.Portfolio h1{
  text-align: center;
}
.container {
  overflow: hidden;
}

#myBtnContainer{
  display: flex;
  justify-content: center;  
}

.btn{
 font-size: 1.3rem;
}

.filterDiv {
  float: left;
  padding: 10px;
  color: #ffffff;
  line-height: 55px;
  text-align: center;
  display: none; /* Hidden by default */
}

.project-name{
  color: black;
}

.project-language{
  color: #E31B6D;
  
}
/* The "show" class is added to the filtered elements */
.show {
  display: block;
}

/* Style the buttons */
.btn {
  border: none;
  outline: none;
  margin: 2px;
  cursor: pointer;
}

/* Add a light grey background on mouse-over */
.btn:hover {
  background-color: #E31B6D;
}

/* Add a dark background to the active button */

.box{
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
.box:before,
.box:after{
  content: "";
  width: 0;
  height: 0;
  background:rgb(216, 213, 213);
  position: absolute;
  transition: all 0.5s ease 0s;
}
.box:before{
  top: 0;
  left: 0;
}
.box:after{
  bottom: 0;
  right: 0;
}
.box:hover:before,
.box:hover:after{
  width: 100%;
  height: 100%;
}
.box img{
  width: 100%;
  height: 300px;
}
.box .social-links{
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: all 0.7s ease 0s;
}
.box .social-links p{
margin: 5px;
color: #E31B6D;
line-height: 25px;
}
.box:hover .social-links{
  opacity: 1;
}
.box .social-links li{
  display: inline-block;
  margin-right: 5px;
}
.box .social-links li a{
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  font-size: 15px;
  color: #E31B6D;
}
.box .social-links li a:hover{
  background: #E31B6D;
  color: #fff;
}
.box .social-links li a:hover i{
  -webkit-animation-name: loading;
  animation-name: loading;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.box p{
  font-family: 'Barlow', sans-serif;
}
@-webkit-keyframes loading{
  25%{
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
  }
  75%{
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}
@keyframes loading{
  25%{
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
  }
  75%{
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}

/*************CONTACT**********/
.Contact{
height: 600px;
background-color: #1b242f;
padding-top: 20px;
padding-bottom: 20px;
color: white;
}
#contact p{
font-family: 'Barlow', sans-serif;
text-align: center;
color: #04c2c9;
font-size: 18px;
}

.p-heading1{
text-align: center;
}
Form{
width: 35%;
min-width: 300px;
margin: auto;
}

.form-control{
background-color: white;
}
.icons{
margin-top: 20px;
display: flex;
justify-content: center;
}

.Contact a{
color: white;
padding-right: 20px;
}

.Contact a:hover{
color: #E31B6D;
}

.Contact a i{
font-size: 20px;
padding: 10px;
}

.submit-btn{
margin: auto;
display: block;
}
#text{
height: 150px;
}
/****************MEDIA QUERIES******************/
@media screen and (max-width: 480px) {
#myBtnContainer button{
  font-size: 0.7rem;
  }
}

@media screen and (max-width: 600px) {
.icon {
  float: right;
  display: block;
  margin: 8px;
  color:white;
}
.icon:hover{
  color: #E31B6D;
}
nav div ul{
  border: none;
}

.nav-items .nav-item {display: none;}
.nav-items.responsive {position: relative;}
.nav-items.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
}
.nav-items.responsive .nav-item{
    float: none;
    display: block;
    text-align: left;
}
}

@media only screen and (max-width: 990px){
.about-me p {
  font-size: 1em;
}
  .box{ margin-bottom: 20px; }
}

@media only screen and (min-width: 1150px){
/**********************TEXT TYPING ANIMATION *****************/

.desc h2{
overflow: hidden; /* Ensures the content is not revealed until the animation */
border-right: .15em solid white; /* The typwriter cursor */
white-space: nowrap; /* Keeps the content on a single line */
margin: 0 auto; /* Gives that scrolling effect as the typing happens */
letter-spacing: .05em; /* Adjust as needed */
animation: 
  typing 3.5s steps(40, end),
  blink-caret .75s step-end infinite;
  width: 1150px;
}



@keyframes typing {
from { width: 0 }
to { width: 85% }
}

@keyframes blink-caret {
from, to { border-color: transparent }
50% { border-color: white; }
}
}